<template>
    <modal ref="modalCrearTarea" titulo="Detalle de la tarea" tamano="modal-lg">
        <div class="row m-3">
            <div class="col-lg-2">
                <div class="bg-f5 br-4 mt-3">
                    <div class="bg-general f-12 text-white border-general br-t-4 text-center">Abr.</div>
                    <div class="f-18 f-700 d-middle-center border-bottom border-right border-left py-1">
                        30
                    </div>
                </div>
            </div>
            <div class="col-10">
                <div class="row titulo-divisor">
                    <div class="col-auto my-auto">
                        <p class="f-600 f-14 ucfirst text-general" >Preparar presentación</p>
                    </div>
                    <div class="col my-auto pl-0">
                        <hr class="my-0 bg-white" />
                    </div>
                    <div class="col-auto my-auto">
                         <div class="row">
                            <div class="col-auto my-auto px-1 icon-option">
                                <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                </el-tooltip>
                                <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <p class="col-12 f-12">8:00 am - 9:00 am</p>
                </div>
                <div class="row mb-3">
                    <div class="col-auto">
                         <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                            <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                            <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <p class="f-600 f-14">Tipo de actividad</p>
                        <p class="f-12">Comercial</p>
                    </div>
                    <div class="col-6">
                        <p class="f-600 f-14">Subtipo de actividad</p>
                        <p class="f-12">Marketing</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 f-12">
                        <p class="f-600">Descripción</p>
                        <p class="lh-15">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCrearTarea.toggle()
        },
        crearTarea(){
            this.toggle()
        },
        editarTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        eliminarTarea(){
            this.$refs.modalEliminarTarea.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
